import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Observable, of } from 'rxjs';
import { CommentsService } from 'src/app/services/comments.service';
import { PhotosService } from 'src/app/services/photos.service';

import { IPhotoComment } from '../../services/comments.service';

@Component({
  selector: 'nan-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit, OnChanges {
  @Input() currentImageName: string;

  @Output() focused: EventEmitter<void> = new EventEmitter();
  @Output() blurred: EventEmitter<void> = new EventEmitter();

  commentNameLocalStorageKey = 'nancyCommentName';
  userName = '';
  comments$: Observable<IPhotoComment[]>;

  commentForm: FormGroup = new FormGroup({
    commenterName: new FormControl(
      localStorage.getItem(this.commentNameLocalStorageKey) || '',
      [Validators.required, Validators.minLength(3)]
    ),
    commentText: new FormControl('', [Validators.required, Validators.minLength(3)]),
  });

  constructor(
    public photosSrv: PhotosService,
    public commentsSrv: CommentsService,
    private route: ActivatedRoute,
    private router: Router,
    private recaptchaV3Srv: ReCaptchaV3Service
  ) { }

  ngOnInit(): void {
    this.userName = localStorage.getItem(this.commentNameLocalStorageKey) || '';
  }

  ngOnChanges() {
    this.comments$ = this.commentsSrv.getComments(this.currentImageName);
  }

  async saveComment() {
    let confirmRecap = this.recaptchaV3Srv.execute('importantAction');
    if (location.href.startsWith('http://localhost')) {
      confirmRecap = of('localhost');
    }

    confirmRecap.subscribe( async (recaptchaResp: string) => {
      const { commenterName, commentText } = this.commentForm.getRawValue();
      localStorage.setItem(this.commentNameLocalStorageKey, commenterName);
      await this.commentsSrv.leaveComment(this.currentImageName, commenterName, commentText, recaptchaResp);
      this.commentForm.reset();
      this.commentForm.controls.commenterName.setValue(localStorage.getItem(this.commentNameLocalStorageKey));
    });
  }

  deleteComment(imageName: string, commentId: string) {
    let confirmRecap = this.recaptchaV3Srv.execute('importantAction');
    if (location.href.startsWith('http://localhost')) {
      confirmRecap = of('localhost');
    }

    confirmRecap.subscribe( async (recaptchaResp: string) => {
      this.commentsSrv.deleteComment(imageName, commentId, recaptchaResp);
    });
  }

}
