<div>
    <nan-slideshow-progress
        [seconds]="imageDurationSeconds"
        [reset$]="resetTimer$"
    ></nan-slideshow-progress>
    <img #momImage nanPic [src]="'assets/mom_photos_all/' + (currentImageName$ | async)" alt="Photo of Nancy">
    <div class="comment" *ngFor="let comment of comments$ | async">
        <b>{{comment.commenterName}}: </b>
        "{{comment.commentText}}"
    </div>
</div>


