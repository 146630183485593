import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, startWith, take } from 'rxjs/operators';
import { IPhotoComment } from 'src/app/services/comments.service';

import { CommentsService } from '../../services/comments.service';
import { PhotosService } from '../../services/photos.service';

@Component({
  selector: 'nan-photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.scss']
})
export class PhotosComponent implements OnInit {
  imgPath = '';
  currentImageName: string;
  progressString = '';
  currentImgUrl = '';
  currentUrl = location.href;
  encodedUrl = window.encodeURI(location.href);
  comments$: Observable<IPhotoComment[]>;
  userName = '';
  nextTwentyPhotos$: BehaviorSubject<string[]> = new BehaviorSubject([]);
  isFocusedOnField = false;

  @HostListener('window:keydown', ['$event'])
  onWindowKeydown(event: any) {
    if (event.key === 'ArrowRight' && !this.isFocusedOnField) {
      this.navigatePhotos('forward');
    } else if (event.key === 'ArrowLeft' && !this.isFocusedOnField) {
      this.navigatePhotos('backward');
    }
  }

  set currentPicture(imageName: string) {
    this.currentImageName = imageName;
    this.comments$ = this.commentsSrv.getComments(imageName);
    this.currentImgUrl = `${location.host}/assets/mom_photos_all/${this.currentImageName}`;
    this.imgPath = `assets/mom_photos_all/${imageName}`;
    this.currentUrl = location.href;
    this.encodedUrl = window.encodeURI(location.href);
    this.photosSrv.imageNames$.pipe(
      filter(images => images.length > 0),
      take(1)
    ).subscribe((allImages: string[]) => {
      const imgIndex = allImages.indexOf(imageName);
      const currentIndex = imgIndex < allImages.length - 20 ? imgIndex + 1 : allImages.length - 20;
      const nextTwenty = allImages.slice(currentIndex - 10 < 0 ? 0 : currentIndex - 10, currentIndex + 10);
      this.nextTwentyPhotos$.next(nextTwenty);
    });
  }

  constructor(
    public photosSrv: PhotosService,
    public commentsSrv: CommentsService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      startWith([])
    ).subscribe(() => {
      this.initImage();
    });
  }

  initImage() {
    const imgParam = this.route.snapshot.params.imageName;
    if (imgParam) {
      this.currentPicture = imgParam;
    } else {
      this.photosSrv.imageNames$.pipe(
        filter(names => names.length > 0),
        take(1)
      ).subscribe((imageNames: string[]) => {
        this.currentPicture = imageNames[0];
        this.progressString = `(${imageNames.indexOf(this.currentImageName) + 1} of ${imageNames.length})`;
      });
    }
  }

  async navigatePhotos(direction: 'forward' | 'backward') {
    const imageNames = this.photosSrv.imageNames$.value;
    const currentIndex = imageNames.indexOf(this.currentImageName);
    let newIndex = 0;
    if (currentIndex === imageNames.length - 1 && direction === 'forward') {
      newIndex = 0;
    } else if (currentIndex === 0 && direction === 'backward') {
      newIndex = imageNames.length - 1;
    } else {
      newIndex = currentIndex + (direction === 'forward' ? 1 : -1);
    }

    await this.router.navigateByUrl(`photos/${imageNames[newIndex]}`);
    this.progressString = `(${newIndex + 1} of ${imageNames.length})`;
    this.ngOnInit();
  }
}
