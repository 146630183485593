<div class="progress-wrapper">
    <div
        class="positive"
        *ngFor="let section of sections; let i = index;"
        [style.flex]="'0 0 calc(100% /' + numberOfSections + ')'"
        [ngClass]="{ 'first': i === 0, 'last': i === sections.length - 1 }"
    >
    </div>
    <div 
        class="empty first last"
    >

    </div>
</div>