import { Component, Input, OnInit } from '@angular/core';
import { interval, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'nan-slideshow-progress',
  templateUrl: './slideshow-progress.component.html',
  styleUrls: ['./slideshow-progress.component.scss']
})
export class SlideshowProgressComponent implements OnInit {
  @Input() seconds = 10;
  @Input() reset$: Observable<void>;
  sections: number[] = [];
  blankArray = [];

  sectionsMultiple = 20;
  numberOfSections = 0;

  constructor() { }

  ngOnInit(): void {
    this.numberOfSections = this.seconds * this.sectionsMultiple;
    this.blankArray = [];
    for (let i = 0; i < this.numberOfSections; i++) {
      this.blankArray.push(undefined);
    }
    this.reset$.pipe(
      tap(() => {
        this.sections = [1];
      }),
      switchMap(() => interval(1000 / this.sectionsMultiple))
    ).subscribe(() => {
      this.sections.push(this.sections[this.sections.length - 1] + 1);
    });
  }

}
