<ng-container *ngIf="comments$ | async as comments">
    <div class="photo-comments-container" *ngIf="comments.length > 0">
        <h3>Comments</h3>
        <div class="photo-comment" *ngFor="let comment of comments">
            <div>
                <h5>{{comment.commenterName}}</h5>
                <span>{{comment.timestamp | date:'short'}}</span>
                <i 
                    *ngIf="userName === comment.commenterName"
                    (click)="deleteComment(currentImageName, comment.id)"
                    class="material-icons"
                    role="button"
                >delete</i>
            </div>
            <div>{{comment.commentText}}</div>
        </div>
    </div>
</ng-container>

<div *ngIf="commentForm" [formGroup]="commentForm" class="photo-comment-box">
    <h4>Leave a comment</h4>
    <p>All comments will be sent directly to the Osterfeld family.</p>
    <label for="name">Your name</label>
    <input 
        placeholder="Leave your name..."
        formControlName="commenterName"
        type="text"
        (focus)="focused.emit()"
        (blur)="blurred.emit()"
        maxlength="200"
    >
    <label for="name">Comment</label>
    <textarea
        placeholder="Enter comment..."
        formControlName="commentText"
        (focus)="focused.emit()"
        (blur)="blurred.emit()"
        maxlength="1000"
    ></textarea>
    <button (click)="saveComment()" [disabled]="commentForm.invalid">Save Comment</button>
</div>