<div class="wrapper">
  <div class="container">
    <h3>Celebration of Life</h3>
    <table>
      <tbody>
        <tr>
          <th>
            Date
          </th>
          <td>
            June 12, 2021, 11am - 2pm
          </td>
        </tr>
        <tr>
          <th>
            Location
          </th>
          <td>
            <a href="https://mapleside.com/" target=_blank>Mapleside Farms in Brunswick, Ohio.</a>
          </td>
        </tr>
        <tr>
          <th>
            Dress Code
          </th>
          <td>
            <em>
              No need to wear black, Nancy would love to be celebrated in color!
            </em>
          </td>
        </tr>
        <tr>
          <th>
            Refreshments
          </th>
          <td>
            <em>
              Coffee, beer, wine, and hors d’oeuvres will be provided.
            </em>
          </td>
        </tr>
      </tbody>
    </table>

    <h4>Order of Events</h4>
    <table>
      <tbody>
        <tr>
          <th>11am - 1pm</th>
          <td>Reception & greetings</td>
        </tr>
        <tr>
          <th>1pm - 2pm</th>
          <td>Speeches & remembrances</td>
        </tr>
      </tbody>
    </table>
  </div>

  <button (click)="addToCalendar()">
    Add to Calendar
  </button>
</div>
