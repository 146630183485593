<a
        class="img-wrapper"
        *ngFor="let img of photosDisplayed$ | async"
        (click)="goToImg(img)"
>
    <div>
        <img
            [src]="('assets/mom_photos_all/' + img)"
            alt="Picture of Nancy"
        >
        <div class="current-image" *ngIf="currentImageName === img">
            <span>Current image</span>
        </div>
    </div>
</a>

<nan-loader *ngIf="isLoading"></nan-loader>

<div #bottomScrollHit class="bottom-scroll-hit"></div>
