import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaFormsModule, RecaptchaV3Module } from 'ng-recaptcha';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoaderComponent } from './components/loader/loader.component';
import { ObituaryTextComponent } from './pages/obituary-text/obituary-text.component';
import { PhotoGalleryComponent } from './pages/photo-gallery/photo-gallery.component';
import { PhotosComponent } from './pages/photos/photos.component';
import { ImageListComponent } from './components/image-list/image-list.component';
import { TrustImgUrlPipe } from './pipes/trust-img-url.pipe';
import { CommentsComponent } from './components/comments/comments.component';
import { SlideshowComponent } from './pages/slideshow/slideshow.component';
import { SlideshowProgressComponent } from './components/slideshow-progress/slideshow-progress.component';
import { PicDirective } from './directives/pic.directive';
import { CelebrationOfLifeComponent } from './components/celebration-of-life/celebration-of-life.component';


const firebaseConfig = {
  apiKey: 'AIzaSyBUhCOEohFIFJR9XnEEtYTLNO5jOkkv7J4',
  authDomain: 'nancyosobit.firebaseapp.com',
  databaseURL: 'https://nancyosobit.firebaseio.com',
  projectId: 'nancyosobit',
  storageBucket: 'nancyosobit.appspot.com',
  messagingSenderId: '149862138622',
  appId: '1:149862138622:web:5e724bd0ef890791119baf',
  measurementId: 'G-RLQHFN9E7Z'
};

@NgModule({
  declarations: [
    AppComponent,
    ObituaryTextComponent,
    PhotosComponent,
    PhotoGalleryComponent,
    LoaderComponent,
    ImageListComponent,
    TrustImgUrlPipe,
    CommentsComponent,
    SlideshowComponent,
    SlideshowProgressComponent,
    PicDirective,
    CelebrationOfLifeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireDatabaseModule,
    ReactiveFormsModule,
    RecaptchaV3Module,
    RecaptchaFormsModule
  ],
  providers: [
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6Lf71t0UAAAAAHPmwJmyk0u5aFWHmikD-fmStya_' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
