import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

import { PhotosService } from '../../services/photos.service';

@Component({
  selector: 'nan-image-list',
  templateUrl: './image-list.component.html',
  styleUrls: ['./image-list.component.scss']
})
export class ImageListComponent implements OnInit {
  @ViewChild('bottomScrollHit') bottomScrollHit: ElementRef<HTMLDivElement>;
  areAllShown = false;
  isLoading = false;
  photosDisplayed$ = new BehaviorSubject([]);
  showMore$: BehaviorSubject<void> = new BehaviorSubject(undefined);
  numShown = 0;
  imageBatchLength = 20;

  @Input() currentImageName: string;
  imageNames = [];

  @HostListener('window:scroll')
  onWindowScroll() {
    const el = this.bottomScrollHit ? this.bottomScrollHit.nativeElement : undefined;
    if (el && this.isElementInViewport(el) && !this.areAllShown && !this.isLoading) {
      this.showMore$.next();
      this.isLoading = true;
    }
  }

  constructor(
    private router: Router,
    private photosSrv: PhotosService
  ) { }

  ngOnInit(): void {
    this.setImageBatchLength();
    combineLatest([this.photosSrv.imageNames$, this.showMore$]).pipe(
      debounceTime(500),
      filter(([allImages, nullVal]: [string[], undefined]) => !!allImages && allImages.length > 0),
    ).subscribe(([allImages, nullVal]: [string[], undefined]) => {
      this.numShown += this.imageBatchLength;
      const numToShowBefore = Math.round(this.imageBatchLength / 2);
      const indexOfImage = !this.currentImageName ? 0 : allImages.indexOf(this.currentImageName);
      const startIndex = indexOfImage < numToShowBefore ? 0 : indexOfImage - numToShowBefore;
      const newImages = allImages.slice(startIndex, startIndex + this.numShown);
      if (indexOfImage > numToShowBefore && newImages.length > 0 && newImages.length < this.numShown) {
        const indexOfFirstImage = allImages.indexOf(newImages[0]);
        const allImagesUntilFirst = allImages.slice(0, indexOfFirstImage);
        const numMoreToShow = this.numShown - newImages.length;
        const imagesToAppend = allImagesUntilFirst.slice(0, numMoreToShow);
        newImages.push(...imagesToAppend);
      }
      this.photosDisplayed$.next(newImages);
      this.isLoading = false;
      this.areAllShown = this.numShown >= allImages.length;
    });
    this.showMore$.next();
  }

  imageHref(imageName: string) {
    return `${location.host}/photos/${imageName}`;
  }

  goToImg(imageName: string) {
    this.router.navigateByUrl(`photos/${imageName}`);
  }

  isElementInViewport(el) {
    let top = el.offsetTop;
    let left = el.offsetLeft;
    const width = el.offsetWidth;
    const height = el.offsetHeight;
    while (el.offsetParent) {
      el = el.offsetParent;
      top += el.offsetTop;
      left += el.offsetLeft;
    }

    return (
      top >= window.pageYOffset &&
      left >= window.pageXOffset &&
      (top + height) <= (window.pageYOffset + window.innerHeight) &&
      (left + width) <= (window.pageXOffset + window.innerWidth)
    );
  }

  setImageBatchLength() {
    const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    if (vw < 600) {
      this.imageBatchLength = 10;
    } else if (vw < 1000) {
      this.imageBatchLength = 20;
    } else {
      this.imageBatchLength = 30;
    }
  }

}
