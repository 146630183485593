import { Component, OnInit } from '@angular/core';
import { ICalendar } from 'datebook';


@Component({
  selector: 'nan-celebration-of-life',
  templateUrl: './celebration-of-life.component.html',
  styleUrls: ['./celebration-of-life.component.scss']
})
export class CelebrationOfLifeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  addToCalendar() {
    const iCalendarEvent = new ICalendar({
      title: 'Nancy Osterfeld\'s Celebration of Life',
      location: '294 Pearl Road Brunswick, OH 44212',
      description: `
        Dress as you see fit - no need to wear black.
        Coffee, beer, wine, and snacks provided.


        Order of Events:
        11a-1p: Reception & greeting
        1p - 2p: Speeches, departure
      `.split('  ').join(''),
      start: new Date(1623510000000),
      end:   new Date(1623520800000)
    });

    iCalendarEvent.download();
  }

}
