<div class="img-container">
    <img alt="Picture of Nancy" src="assets/primary-img.jpg">
</div>

<div class="life-dates">
    July 3, 1959 - March 3, 2020
</div>

<div 
    *ngIf="coronaAnouncement$ | async as announcement"
    class="corona-announcement"
    [innerHTML]="sanitizer.bypassSecurityTrustHtml(announcement)">
</div>

<div>
    <div>
        <nan-celebration-of-life></nan-celebration-of-life>
    </div>

    <div>
        <h3>Obituary</h3>
    </div>

    <div>
        Nancy Wirka Osterfeld died on March 3rd, 2020 of complications 
        from Alzheimer’s Disease. Beloved wife, mother, sister and 
        domestic goddess, Nancy will be remembered by her husband Mike, 
        her sons Will and Joe and daughter Annie as well as her siblings Bob Jr., 
        Mary and Barbara Jean.
    </div>

    <div>
        Nancy was born in 1959 in Elgin, Illinois and grew up down the road in 
        Arlington Heights. She graduated from the University of Iowa. She married 
        Mike in 1988 and spent the last 23 years in Strongsville, Ohio.   
    </div>
</div>

<div class="links-container">
    <div>
        Instead of flowers, please 
        <a href="https://act.alz.org/site/TR/Events/Tributes-AlzheimersChampions?pg=fund&fr_id=1060&pxfid=613686" target="_blank">
            donate to Nancy's Alzheimers association tribute page.
        </a>
    </div>

    <a class="link-button" routerLink="photos" aria-label="View Photos">
        View Photos 
        <i class="material-icons">keyboard_arrow_right</i>
    </a>
</div>

<nan-comments currentImageName="main_page"></nan-comments>
