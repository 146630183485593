import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, interval, merge, Observable, of, Subject } from 'rxjs';
import { filter, map, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { CommentsService, IPhotoComment } from '../../services/comments.service';
import { PhotosService } from '../../services/photos.service';

@Component({
  selector: 'nan-slideshow',
  templateUrl: './slideshow.component.html',
  styleUrls: ['./slideshow.component.scss']
})
export class SlideshowComponent implements OnInit, OnDestroy {
  @ViewChild('momImage') momImage: ElementRef<HTMLImageElement>;
  imageDurationSeconds = 15;
  currentImageName$: Observable<string>;
  resetTimer$: Subject<void> = new Subject();
  goToIndex$: BehaviorSubject<number> = new BehaviorSubject(0);
  currentIndex = 0;
  destroy$ = new Subject();
  comments$: Observable<IPhotoComment[]>;

  @HostListener('window:keyup', ['$event'])
  onKeyPress(ev: any) {
    if (ev.key === 'ArrowRight') {
      this.goForward();
    } else if (ev.key === 'ArrowLeft') {
      this.goBack();
    }
  }

  constructor(
    private photosSrv: PhotosService,
    private commentsSrv: CommentsService
  ) { }

  ngOnInit(): void {
    this.currentImageName$ = merge(
      this.goToIndex$,
      of(undefined).pipe(
        switchMap(() => this.goToIndex$),
        switchMap(() => interval(this.imageDurationSeconds * 1000)),
        map(() => {
          this.currentIndex++;
          return this.currentIndex;
        })
      )
    ).pipe(
      mergeMap((index: number) => {
        return this.photosSrv.imageNames$.pipe(
          filter(names => names.length > 0),
          take(1),
          map((names: string[]) => names[index]));
      }),
      filter((name: string) => !!name),
      tap((imageName: string) => {
        this.momImage.nativeElement.onload = () => {
          this.comments$ = this.commentsSrv.getComments(imageName);
        };
        const imageNames = this.photosSrv.imageNames$.value;
        if (this.currentIndex >= imageNames.length - 1) {
          this.currentIndex = 0;
        }
        this.resetTimer$.next();
      }),
      takeUntil(this.destroy$)
    );
  }

  ngOnDestroy() {
    this.destroy$.next(); this.destroy$.complete();
  }

  goForward() {
    this.currentIndex++;
    this.goToIndex$.next(this.currentIndex);
  }

  goBack() {
    this.currentIndex--;
    this.goToIndex$.next(this.currentIndex);
  }



}
