import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';

export interface IPhotoComment {
  id: string;
  commenterName: string;
  commentText: string;
  timestamp: number;
  imageName: string;
}

@Injectable({
  providedIn: 'root'
})
export class CommentsService {
  commentsPath = 'photo_comments';

  constructor(
    private firebaseDb: AngularFireDatabase,
    private http: HttpClient
  ) { }

  getComments(imageName: string): Observable<IPhotoComment[]> {
    const imgPath = this.getImgPath(imageName);
    return this.firebaseDb.list(imgPath).valueChanges() as any;
  }

  leaveComment(imageName: string, commenterName: string, commentText: string, captchaString): Promise<any> {
    const comment: IPhotoComment = {
      id: this.firebaseDb.createPushId(),
      commenterName,
      commentText,
      timestamp: new Date().getTime(),
      imageName
    };
    return this.http.post('api/comment', comment, {
      headers: new HttpHeaders({ authorization: captchaString })
    }).toPromise();
  }

  deleteComment(imageName: string, commentId: string, captchaString: string): Promise<void> {
    return this.http.delete(`api/comment?imageName=${imageName}&commentId=${commentId}`, {
      headers: new HttpHeaders({ authorization: captchaString })
    }).toPromise().then(() => undefined);
  }

  private getImgPath(imgName: string) {
    return `${this.commentsPath}/${imgName.replace(/[.]/g, '')}`;
  }
}
