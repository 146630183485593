import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ObituaryTextComponent } from './pages/obituary-text/obituary-text.component';
import { PhotoGalleryComponent } from './pages/photo-gallery/photo-gallery.component';
import { PhotosComponent } from './pages/photos/photos.component';
import { SlideshowComponent } from './pages/slideshow/slideshow.component';


const routes: Routes = [
  { path: '', component: ObituaryTextComponent },
  { path: 'photos', component: PhotoGalleryComponent },
  { path: 'photos/:imageName', component: PhotosComponent },
  { path: 'slideshow', component: SlideshowComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
