
<nav>
    <div>
        <img routerLink="." src="./favicon.ico">
        <span>Nancy</span>
    </div>
</nav>

<div class="page-wrapper">
    <div class="page-container">
        <router-outlet></router-outlet>
    </div>  
</div>


<footer>
    In loving memory of Nancy Wirka Osterfeld. March 2020.
    <a style="text-decoration: underline;" (click)="viewSlideshow()">View slideshow</a>
</footer>