import { Directive, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[nanPic]'
})
export class PicDirective implements OnChanges, OnInit {
  @Input() src: string;

  rotateNinety = [
    '13-12-22-cookie_time.jpg',
    '14-2-06-n_on_v-day.jpg',
    '16-8-29-babe_at_the_indians_game.jpg',
    '17-xx-xx_annie_will_dad_osu.jpg',
    '75-xx-xx_yearbook.jpg',
    'dsc06047.jpg',
    'dsc06238.jpg'
  ];
  rotateOneEighty = [
    '16-3-27-osterfelds_at_easter_on_kerry_pl.jpg',
    '83-6-_-kris_dave_r_o_rehearsal_dinner.jpeg'
  ];
  rotateTwoSeventy = [
    '95-2-1_annie_newborn.jpg',
    'august2006_044.jpg',
    'xx-xx-xx_family_tuscon_.jpg'
  ];

  classes = {
    ninety: 'rotate-ninety',
    oneEighty: 'rotate-one-eighty',
    twoSeventy: 'rotate-two-seventy'
  };

  constructor(
    private ref: ElementRef<HTMLImageElement>
  ) { }

  ngOnInit() {
    this.updateClasses();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.updateClasses();
  }

  updateClasses() {
    const segments = this.src.split('/');
    const imageName = segments[segments.length - 1];
    if (this.rotateNinety.includes(imageName)) {
      this.addClass(this.classes.ninety);
    } else if (this.rotateOneEighty.includes(imageName)) {
      this.addClass(this.classes.oneEighty);
    } else if (this.rotateTwoSeventy.includes(imageName)) {
      this.addClass(this.classes.twoSeventy);
    } else {
      this.resetClass();
    }
    this.ref.nativeElement.src = this.src;
  }

  addClass(className: string) {
    this.ref.nativeElement.className += ` ${className}`;
  }

  resetClass() {
    let currentClass = this.ref.nativeElement.className;
    for (const className of [this.classes.ninety, this.classes.oneEighty, this.classes.twoSeventy]) {
      if (currentClass.includes(className)) {
        currentClass = currentClass.split(className).join('');
      }
    }
    this.ref.nativeElement.className = currentClass;
  }

}
