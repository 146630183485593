
<div class="photos-container">
    <div class="img-wrapper">
        <img 
            [id]="currentImgUrl"
            [src]="imgPath"
            alt="Picture of Nancy"
        >
    </div>
</div>

<div class="photo-nav-buttons">
    <a class="link-button" alt="Previous" (click)="navigatePhotos('backward')">
        <i class="material-icons">keyboard_arrow_left</i>
        Prev
    </a>
    <a class="link-button" alt="Next" (click)="navigatePhotos('forward')">
        Next
        <i class="material-icons">keyboard_arrow_right</i>
    </a>
</div>
<div class="photo-progress">{{progressString}}</div>

<div class="share-buttons">
    <a class="link-text" alt="Previous" routerLink=".."><< Back to gallery</a>

    <div 
        class="fb-share-button" 
        [attr.data-href]="currentUrl" 
        data-layout="button" 
        data-size="large"
    >
        <a 
            class="fb-share-anchor"
            target="_blank" 
            href="https://www.facebook.com/sharer/sharer.php?u={{encodedUrl}}&amp;src=sdkpreparse" 
            class="fb-xfbml-parse-ignore"
        >
            Share
        </a>
    </div>
</div>

<nan-comments
    [currentImageName]="currentImageName"
    (focused)="isFocusedOnField = true"
    (blurred)="isFocusedOnField = false"
></nan-comments>

<div class="next-twenty-container">
    <h3>More Images</h3>
</div>
<div style="text-align: center;">
    <nan-image-list
        [currentImageName]="currentImageName"
    ></nan-image-list>
</div>
