import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Observable } from 'rxjs';

@Component({
  selector: 'nan-obituary-text',
  templateUrl: './obituary-text.component.html',
  styleUrls: ['./obituary-text.component.scss']
})
export class ObituaryTextComponent implements OnInit {
  coronaAnouncement$: Observable<SafeHtml>;

  constructor(
    private firebaseDb: AngularFireDatabase,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.coronaAnouncement$ = this.firebaseDb.object('corona_announcement').valueChanges();
  }

}
