import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PhotosService {
  public imageNames$: BehaviorSubject<string[]> = new BehaviorSubject([]);

  constructor(
    private http: HttpClient
  ) {
    this.http.get('assets/image-names.json').pipe(
      take(1)
    ).subscribe((names: string[]) => this.imageNames$.next(names));
  }

  // rotateClass(imageName: string) {
  //   const ninetyClassName = 'rotate-ninety';
  //   const oneEightyClassName = 'rotate-one-eighty';
  //   const twoSeventyClassName = 'rotate-two-seventy';
  //   const lookup: { [imageName: string]: string } = {
  //     '16-3-27-osterfelds_at_easter_on_kerry_pl.jpg': oneEightyClassName,
  //     '14-2-06-n_on_v-day.jpg': ninetyClassName
  //   };
  //   return lookup[imageName] || 0;
  // }
}
